import { createStore as reduxCreateStore } from "redux";
import { v4 as uuidv4 } from "uuid";

const reducer = (state, action) => {
  if (action.type === `ADD_FILTER` || action.type === `REMOVE_FILTER`) {
    const { carrierKey, item, value } = action.payload;
    const filters = state.filters || {};

    if (!filters[carrierKey][item.type]) filters[carrierKey][item.type] = {};

    if (item.type == "CHECKBOX") {
      if (!filters[carrierKey][item.type][item.attribute])
        filters[carrierKey][item.type][item.attribute] = new Set();

      const currentSet: Set<String> =
        filters[carrierKey][item.type][item.attribute];

      if (action.type === `ADD_FILTER`) {
        currentSet.add(value);
      } else {
        currentSet.delete(value);
      }

      filters[carrierKey][item.type][item.attribute] = currentSet;
      return {
        ...state,
        ...{ filters },
      };
    } else if (item.type == "MIN_MAX_SLIDER") {
      filters[carrierKey][item.type][item.attribute] = {
        min: value[0],
        max: value[1],
      };

      return {
        ...state,
        ...{ filters },
      };
    }
  }

  if (action.type == "SORT") {
    return {
      ...state,
      ...{ sort: action.payload },
    };
  }

  if (action.type == "FETCH_QUOTES") {
    return {
      ...state,
    };
  }

  if (action.type == "SET_PARAMS") {
    return {
      ...state,
      ...{ params: action.payload },
    };
  }

  if (action.type == "SET_ADDITIONAL_INFO") {
    state.enrollment.nationalGeneral.additionalInfo[action.payload.key] =
      action.payload.value;
    return {
      ...state,
    };
  }

  if (action.type == "SET_APPLICATIONS") {
    return {
      ...state,
      applicants: {
        main_applicant: action.payload.main_applicant,
        spouse: action.payload.spouse,
        dependents: action.payload.dependents,
      },
      enrollment: {
        nationalGeneral: {
          ...state.enrollment.nationalGeneral,
          plans: action.payload.plans,
          monthlyValue: action.payload.monthlyValue,
          fees: action.payload.fees,
          applications: action.payload.applications.map((a) => ({
            ...a,
            applicationId: uuidv4(),
          })),
        },
      },
    };
  }

  if (action.type == "ANSWER_ELIGIBILITY_QUESTION") {
    state.enrollment.nationalGeneral.applications[
      action.payload.application_index
    ].eligibilityQuestions[action.payload.index]["answer"] =
      action.payload.answer;

    return {
      ...state,
      enrollment: { ...state.enrollment },
    };
  }

  if (action.type == "START_NATIONAL_GENERAL_ENROLLMENT") {
    return {
      ...state,
    };
  }

  if (action.type == "SET_TERMS") {
    state.enrollment.nationalGeneral = {
      ...state.enrollment.nationalGeneral,
      availableTerms: action.payload,
    };

    return {
      ...state,
    };
  }

  if (action.type == "UPDATE_CART_VALUES") {
    const fees = [];

    if (action.payload.fees && action.payload.fees.ONE_TIME) {
      fees.push({
        label: "One time fees",
        value: action.payload.fees.ONE_TIME.total,
      });
    }

    if (action.payload.fees && action.payload.fees.MONTHLY) {
      fees.push({
        label: "Monthly fees",
        value: action.payload.fees.MONTHLY.total,
      });
    }

    return {
      ...state,
      enrollment: {
        ...state.enrollment,
        nationalGeneral: {
          ...state.enrollment.nationalGeneral,
          plans: action.payload.plans,
          monthlyValue: action.payload.plans.reduce((a, b) => {
            return a + b.premium.value;
          }, 0),
          fees: fees,
        },
      },
    };
  }

  if (action.type == "UPDATE_SPECIAL_RATES") {
    return {
      ...state,
      specialRates: {
        ...action.payload,
      },
    };
  }

  return state;
};

const initialState = (siteMetadata) => ({
  baseUrl: siteMetadata?.baseUrl,
  agentMetadata: siteMetadata?.site?.siteMetadata?.agent,
  facets: [],
  results: {},
  params: {},
  quote_id: "",
  enrollment: {
    nationalGeneral: {
      planIds: [],
      applications: [],
      additionalInfo: {
        height: 0,
        spouse: {},
        dependents: [],
      },
    },
  },
  sort: {
    by: "Premium",
    order: "asc",
  },
  filters: {
    ALL: {},
    MARKETPLACE: {},
    NATIONAL_GENERAL: {},
    ONE_SHARE: {},
    UNITED_HEALTHCARE: {},
  },
});

const createStore = (siteMetadata) => {
  try {
    if (window) {
      return reduxCreateStore(
        reducer,
        initialState(siteMetadata),
        window.__REDUX_DEVTOOLS_EXTENSION__ &&
          window.__REDUX_DEVTOOLS_EXTENSION__()
      );
    } else {
      return reduxCreateStore(reducer, initialState(siteMetadata));
    }
  } catch (e) {
    return reduxCreateStore(reducer, initialState(siteMetadata));
  }
};
export default createStore;
